import * as React from "react"

export function Footer( {privacyLinkText, img} ){
  return (
    <footer className="footer">
      <div className="container-fluid d-flex justify-content-between align-items-center">

        <img className="footer__logo" src={img.src} alt={img.alt} />

        <a href="https://static.iohk.io/terms/iog-privacy-policy.pdf" target="_blank" className="footer__link small" rel="noreferrer">{privacyLinkText}</a>

      </div>
    </footer>
  )
}
